import React, { useState, useEffect, useRef, useCallback } from "react";

import LogService from "core-ui/client/src/app/core/logger/LogService";
import MaskedInput from "react-text-mask";
import Form from "react-validation/build/form";

import { useTranslations } from "../../../queries";
import { verifyOneTimePasswordCode } from "../../../services/idProof/otp/OneTimePasswordService";

interface IdentityVerificationComponentProps {
    noCodeFunction: () => void;
    options: {
        isOtsUser: boolean;
        onCancel: () => void;
        onConfirm: (phoneNumber: string, data: any) => void;
        onOtpError: (state?: string) => void;
        phoneNumber: string;
    };
}
interface VerifyCodeEntryTranslations {
    didNotRecieveCode: string;
    oneTimePassword: {
        identityVerification: {
            agreeButtonProcessing: string;
            errors: {
                [key: string]: string;
                UNKNOWN: string;
            };
        };
        verificationCodeEntry: {
            codeSentMessageLogin: string;
            header: string;
        };
    };
    pleaseEnterCode: string;
    verificationcodeRequired: string;
}

const VerificationCodeEntryComponent = ({
    options,
    noCodeFunction
}: IdentityVerificationComponentProps) => {
    const [verificationCode, setVerificationCode] = useState<string | null>(null);
    const [verificationCodeError, setVerificationCodeError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const formRef = useRef<Form | null>(null);
    const { oneTimePassword, didNotRecieveCode, pleaseEnterCode, verificationcodeRequired } =
        useTranslations<VerifyCodeEntryTranslations>();
    const isEmpty = (str) => {
        if (str === null || str === undefined) {
            return true;
        }
        if (str.replace(/\s/g, "") === "") {
            return true;
        }
        return false;
    };

    const validateVerificationCode = (value) => {
        setVerificationCode(value);
        if (!value || isEmpty(value)) {
            setVerificationCodeError(verificationcodeRequired);
            return;
        }
        setVerificationCodeError(null);
    };

    const handleVerificationCodeChange = (event) => {
        const value = event.target.value;
        validateVerificationCode(value);
    };

    const renderVerificationCodeErrorMessage = useCallback(() => {
        if (verificationCodeError) {
            return (
                <div className="form-group has-error" id="screenReader" tabIndex={0}>
                    <span id="helpBlock" className="help-block">
                        {verificationCodeError}
                    </span>
                </div>
            );
        }
    }, [verificationCodeError]);

    const handleError = (data, error) => {
        LogService.getLogger().error(
            "handleError. data=[" +
                (data ? JSON.stringify(data) : "null") +
                "] error: " +
                (error ? JSON.stringify(error) : "null")
        );

        let contentLabel = oneTimePassword?.identityVerification?.errors.UNKNOWN;

        if (data && data.errorCode) {
            const variableLabel = oneTimePassword?.identityVerification?.errors[data.errorCode];
            if (variableLabel) {
                contentLabel = variableLabel;
            }

            if (data.state === "MAIL_PIN") {
                if (options.onOtpError) {
                    if (data.flowName === "otsAccountSetup") {
                        options.onOtpError();
                    } else {
                        options.onOtpError(data.state);
                    }
                }
            } else {
                setIsLoading(false);
                setVerificationCodeError(contentLabel);
            }
        }
    };

    const handleSuccess = (data) => {
        LogService.getLogger().error(
            "handleSuccess. data=[" + (data ? JSON.stringify(data) : "null") + "]"
        );
        setIsLoading(false);

        if (options.onConfirm) {
            options.onConfirm(options.phoneNumber, data);
        }
    };

    const handleNoCodeReceived = (e) => {
        e.preventDefault();
        if (options.isOtsUser) {
            options.onOtpError();
        } else {
            noCodeFunction();
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        validateVerificationCode(verificationCode);
        if (isFormValid()) {
            verifyOneTimePasswordCode({ value: verificationCode }, handleSuccess, handleError);
        } else {
            setIsLoading(false);
        }
    };

    const isFormValid = () => {
        return !!verificationCode && !verificationCodeError;
    };

    const getFieldClass = () => {
        let rc = "form-group";
        if (verificationCodeError) {
            rc += " has-error";
        }
        return rc;
    };

    const renderSubmitButton = () => {
        let className = "btn btn-primary btn-lg btn-block margin-top-default ";
        let disabled = false;
        let label = "Sign In";

        if (isFormValid()) {
            if (isLoading) {
                disabled = true;
                label = oneTimePassword?.identityVerification?.agreeButtonProcessing;
                className += " pw-loader disabled-loading";
            }
        } else {
            disabled = true;
        }

        return (
            <button className={className} id="signin" disabled={disabled} onClick={handleSubmit}>
                {label}
            </button>
        );
    };

    useEffect(() => {
        renderVerificationCodeErrorMessage();
    }, [renderVerificationCodeErrorMessage]);

    return (
        <div className="container row">
            <div className="row">
                <div
                    className="registration col-xs-8 col-xs-offset-4"
                    data-testid="verification-code-entry-component"
                >
                    <header className="contained-form-header">
                        <h1>{oneTimePassword?.verificationCodeEntry?.header}</h1>
                        <p>{oneTimePassword?.verificationCodeEntry?.codeSentMessageLogin}</p>
                    </header>
                    <div className="tab-content hidden-xs">
                        <div role="tabpanel" className="tab-pane active">
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <div className={`form-group ${getFieldClass()}`}>
                                    <label htmlFor="verificationCode" className="control-label">
                                        {pleaseEnterCode}
                                    </label>
                                    <MaskedInput
                                        mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control col-12"
                                        guide={false}
                                        id="verificationCode"
                                        onBlur={handleVerificationCodeChange}
                                        onChange={handleVerificationCodeChange}
                                        autoComplete="off"
                                        aria-label="verificationCode"
                                    ></MaskedInput>
                                    {renderVerificationCodeErrorMessage()}
                                </div>
                                <a href="#" className="no-code" onClick={handleNoCodeReceived}>
                                    {didNotRecieveCode}
                                </a>

                                <div className="form-group clearfix">{renderSubmitButton()}</div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificationCodeEntryComponent;
