import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PropTypes from "prop-types";

import LoginForm from "../LoginForm";

const queryClient = new QueryClient();

const LoginFormContainer = ({
    accu,
    authentication,
    authenticate,
    loggingIn,
    showLogin,
    errorCode,
    errorMessageParams,
    credentials,
    registerButtonClick,
    openAccountClick
}) => {
    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <LoginForm
                    accu={accu}
                    authentication={authentication}
                    authenticateInput={authenticate}
                    loggingIn={loggingIn}
                    showLoginUpd={showLogin}
                    errorCode={errorCode}
                    errorMessageParams={errorMessageParams}
                    credentials={credentials}
                    registerButtonClickInput={registerButtonClick}
                    openAccountClickInput={openAccountClick}
                    loginDomain={__baseApiUrl__}
                />
            </QueryClientProvider>
        </React.StrictMode>
    );
};

LoginFormContainer.propTypes = {
    accu: PropTypes.string,
    authentication: PropTypes.object,
    authenticate: PropTypes.func,
    loggingIn: PropTypes.bool,
    showLogin: PropTypes.bool,
    errorCode: PropTypes.string,
    errorMessageParams: PropTypes.any,
    credentials: PropTypes.object,
    registerButtonClick: PropTypes.func,
    openAccountClick: PropTypes.func
};

export default LoginFormContainer;
