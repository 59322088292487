const preconsentTemplate = `
<div class="preconsentTemplate" ng-controller="oauthController">
  <form role="form" name="getUrlForm-form" data-ng-init="getConsentURL()">


    <div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 inner-container with-padding with-shadow"
      style="padding: 20px;border: 1px solid #ddd;">
      <div class="oauth_client_details" style="margin-bottom: 5%;">
        <div class="oauth_client_logo" style="float: left;margin-right: 5%;"><img src="{{oauth.logoUri}}" /></div>
        <div class="oauth_client_name">

          <h3>{{oauth.clientName}}</h3>

        </div>
      </div>


      <div>
        <form role="form" name="consent-form" data-ng-submit="">
          <p>is requesting that your retirement plan recordkeeper share information about your account(s).</p>
          <p><b>This will include access to general information about your retirement account(s) and your transaction
              history.
              Your retirement plan recordkeeper is not able to retract this information once it has been shared.</b></p>
          <!--<div ng-repeat="grantScopes in GrantScopes">
            <label for="chkCustomer_{{grantScopes.Value}}">
              <label ng-model="grantScopes.Selected">
                {{grantScopes.Name}}
              </label>
            </label>
          </div>-->





          <div class="form-group">
            <button type="submit" class="btn btn-primary btn-lg margin-top-default"
              ng-disabled="showSpinner==true" ng-click="acceptConsent('consentData')" id="accept">Authorize
            </button>
            <button type="submit" class="btn btn-primary btn-lg margin-top-default"
              ng-disabled="showSpinner==true" ng-click="rejectConsent('consentChallenge')" id="deny">Deny
            </button>
          </div>
        </form>
        <div style="margin-top:20px;">
          <p>By clicking on "Authorize" you grant this application access to your account(s), subject to the
            <a href="#/articles/terms" target="_blank">Terms & Conditions</a> and
            <a href="#/articles/privacy" target="_blank">Privacy Policy</a>.
          </p>
        </div>

      </div>

    </div>
  </form>
</div>
`;
export default preconsentTemplate;
