import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import parse, { domToReact } from "html-react-parser";

import { findNodeType, updateAccuCustomization } from "./index"; // TBD

/**
 * ui-style-guide /src/customizations/Default/articles/mobileDefaultLanding.html"
 *                /src/customizations/Default/articles/mobileDefaultLandingNew.html"  /'Default' version for react code
 *
 * Fetch /mobileDefaultLanding from ui-style-guide based on url in accuCustomization blockingMobileTemplate. Replace accuCustomization values
 * in template with values from accuCustomization using html-react-parser
 *
 * @param {*} param0
 * @returns
 */
const TemplateBlockingMobile = ({ styleEnv, accuCustomization }) => {
    // ToDo: Implement the TemplateBlockingMobile component
    //
    const [htmtlFileString, setHtmlFileString] = useState();
    const [htmlParsed, setHtmlParsed] = useState("");

    const initTag = (domNode, _tagType) => {
        return String(domNode.children[0].data).replace("accuCustomization.", "");
    };

    const replaceTag = useCallback(
        (domNode) => {
            let type = "";

            let updatedText = "";
            if (domNode.type === "tag" && domNode.name === "h2") {
                type = findNodeType({ domNode, tagType: "text" });
                updatedText = initTag(domNode, "text");

                updatedText = updateAccuCustomization({ type, updatedText, accuCustomization });
                return <h2 className={domNode.attribs.classname || ""}>{updatedText}</h2>;
            }
            if (domNode.type === "tag" && domNode.name === "span") {
                // has 2 accuCustomization values
                let accuCustomizationValues = String(domNode.children[0].data).replaceAll(
                    "accuCustomization.",
                    ""
                );
                accuCustomizationValues = String(accuCustomizationValues).split("+");
                const item1 = accuCustomizationValues[0];
                const item2 = accuCustomizationValues[1]; // has html character code

                updatedText = updateAccuCustomization({
                    type,
                    updatedText: item1,
                    accuCustomization
                });

                updatedText = updateAccuCustomization({
                    type: "global",
                    updatedText: item2,
                    accuCustomization,
                    subType: "text"
                });

                const hasRightArrows = String(item2).replace(" ", "") === "&raquo;";
                updatedText = hasRightArrows ? `${updatedText} &raquo;` : updatedText;

                // MinnesotWR has embedded html tags, ie. <u> in span tag; parses sub tags for span
                // also converts html character code, &raquo; to actual character >>
                updatedText = parse(updatedText);

                return <span className={domNode.attribs.classname || ""}>{updatedText}</span>;
            }

            if (domNode.type === "tag" && domNode.name === "h3") {
                type = findNodeType({ domNode, tagType: "text" });
                updatedText = initTag(domNode, "text");

                updatedText = updateAccuCustomization({ type, updatedText, accuCustomization });
                return <h3 className={domNode.attribs.classname || ""}>{updatedText}</h3>;
            }

            if (domNode.type === "tag" && domNode.name === "p") {
                type = findNodeType({ domNode, tagType: "text" });
                updatedText = initTag(domNode, "text");

                updatedText = updateAccuCustomization({ type, updatedText, accuCustomization });
                return <p className={domNode.attribs.classname}>{updatedText}</p>;
            }

            if (domNode.type === "tag" && domNode.name === "a") {
                type = findNodeType({ domNode, tagType: "a" });
                let hrefText = String(domNode.attribs.href).replace("accuCustomization.", "");

                updatedText = updateAccuCustomization({
                    type,
                    updatedText: hrefText,
                    accuCustomization
                });
                hrefText = updatedText;

                let targetText = String(domNode.attribs.target).replace("accuCustomization.", "");

                updatedText = updateAccuCustomization({
                    type,
                    updatedText: targetText,
                    accuCustomization
                });
                targetText = updatedText;

                let titleText = domNode.attribs.title
                    ? String(domNode.attribs.title).replace("accuCustomization.", "")
                    : null;

                if (titleText) {
                    titleText = String(titleText)
                        .replace("Link to", "")
                        .replace(" page", "")
                        .replaceAll("+", "")
                        .replaceAll("'", "");

                    updatedText = updateAccuCustomization({
                        type,
                        updatedText: titleText,
                        accuCustomization
                    });
                    titleText = `Link to  ${updatedText}  page`;
                }

                const replaceWithChildren = domToReact(domNode.children, {
                    replace: replaceTag
                });

                // MinnesotaWR has no title attribute value
                return (
                    <a
                        className={domNode.attribs.classname}
                        href={hrefText}
                        target={targetText}
                        title={titleText}
                    >
                        {replaceWithChildren}
                    </a>
                );
            }
            if (domNode.type === "tag" && domNode.name === "img") {
                type = findNodeType({ domNode, tagType: "img" });
                let srcText = String(domNode.attribs.src)
                    .replace("styleEnv+", "")
                    .replace("accuCustomization.", "");

                updatedText = updateAccuCustomization({
                    type,
                    updatedText: srcText,
                    accuCustomization
                });
                srcText = updatedText;
                let srcSetText = String(domNode.attribs.srcset)
                    .replace("styleEnv+", "")
                    .replace("accuCustomization.", "");

                updatedText = updateAccuCustomization({
                    type,
                    updatedText: srcSetText,
                    accuCustomization
                });

                srcSetText = updatedText;

                const altList = String(domNode.attribs.alt).split("||");
                let altOpt1 = altList[0].replace("accuCustomization.", "");
                let altOpt2 = altList[1].replace("accuCustomization.", "");

                updatedText = updateAccuCustomization({
                    type,
                    updatedText: altOpt1,
                    accuCustomization
                });
                altOpt1 = updatedText;

                updatedText = updateAccuCustomization({
                    type,
                    updatedText: altOpt2,
                    accuCustomization
                });
                altOpt2 = updatedText;

                return <img src={styleEnv + srcText || ""} alt={altOpt1 || altOpt2}></img>;
            }

            if (domNode.type === "script") {
                const script = document.createElement("script");
                script.type = domNode.attribs.type;
                script.src = eval(domNode.children[0].data);

                document.head.appendChild(script);
            }
        },
        [accuCustomization, styleEnv]
    );

    useEffect(() => {
        if (accuCustomization) {
            const templateHtml = accuCustomization.blockingMobileTemplate;
            const templateUrl = __styleEnv__ + String(templateHtml).replace(".html", "New.html");

            fetch(templateUrl)
                .then((resPromise) => {
                    resPromise
                        .text()
                        .then((res) => {
                            setHtmlFileString(res);

                            console.log("fetch text", res);
                        })
                        .catch((err) => {
                            console.log("fetch text error", err);
                        });
                })
                .catch((err) => {
                    console.log("fetch promise error", err);
                });
        }
    }, [accuCustomization]);

    useEffect(() => {
        if (htmtlFileString && htmlParsed === "") {
            const reactElements = parse(htmtlFileString, { replace: replaceTag });
            setHtmlParsed(reactElements);
        }
    }, [htmtlFileString, htmlParsed, replaceTag]);

    console.log("htmlParsed", htmlParsed);

    return <React.Fragment>{htmtlFileString ? <div>{htmlParsed}</div> : null}</React.Fragment>;
};

TemplateBlockingMobile.propTypes = {
    styleEnv: PropTypes.string,
    accuCustomization: PropTypes.object
};

export default TemplateBlockingMobile;
