import "angular";
import "angular-resource";
import "angular-ui-router";
import "../browserNotification/BrowserNotificationModule";
import CSRFTokenFactory from "../core/CSRFTokenFactory";
import StateDestinationFactory from "../core/StateDestinationFactory";
import StateInterceptor from "../core/StateInterceptor";

import AppleRegSplashController from "./apple/AppleRegSplashController";
import Authenticate from "./AuthenticationController";
import routeStates from "./AuthenticationRoute";
import AuthenticationService from "./AuthenticationService";
import BaseEncoder from "./BaseEncoderService";
import BasicAuthentication from "./BasicAuthenticationService";
import EligibilityService from "./EligibilityService";
import PAESSOAuthFactory from "./pae/PAESSOAuthFactory";
import PAESSOLandingController from "./pae/PAESSOLandingController";
import PreLoginAccuRetrievalService from "./PreLoginAccuRetrievalService";
import PreLoginBulletinService from "./PreLoginBulletinService";
import PreLoginContactInfoService from "./PreLoginContactInfoService";
import ReSetACCUService from "./ReSetACCUService";
import SetACCUService from "./SetACCUService";
import InboundSSOAuthFactory from "./ssolanding/InboundSSOAuthFactory";
import InboundSSOLandingController from "./ssolanding/InboundSSOLandingController";
import MobileSSOController from "./ssolanding/MobileSSOController";

// eslint-disable-next-line no-undef
const authentication = angular.module("authentication", [
    "ngResource",
    "ui.router",
    "BrowserNotificationModule"
]);

authentication.config(routeStates);
authentication.config([
    "$httpProvider",
    function ($httpProvider) {
        $httpProvider.interceptors.push("StateInterceptor");
    }
]);
authentication.controller("authenticationController", Authenticate);
authentication.controller("PAESSOLandingController", PAESSOLandingController);
authentication.controller("InboundSSOLandingController", InboundSSOLandingController);
authentication.controller("MobileSSOController", MobileSSOController);
authentication.controller("AppleRegSplashController", AppleRegSplashController);
authentication.factory("StateInterceptor", StateInterceptor);
authentication.factory("PAESSOAuthFactory", PAESSOAuthFactory);
authentication.factory("InboundSSOAuthFactory", InboundSSOAuthFactory);
authentication.factory("AuthenticationService", AuthenticationService);
authentication.factory("PreLoginAccuRetrievalService", PreLoginAccuRetrievalService);
authentication.factory("SetACCUService", SetACCUService);
authentication.factory("ReSetACCUService", ReSetACCUService);
authentication.factory("PreLoginContactInfoService", PreLoginContactInfoService);
authentication.factory("PreLoginBulletinService", PreLoginBulletinService);
authentication.factory("BasicAuthenticationService", BasicAuthentication);
authentication.factory("StateDestinationFactory", StateDestinationFactory);
authentication.factory("csrfTokenFactory", CSRFTokenFactory);
authentication.factory("EligibilityService", EligibilityService);
authentication.factory("base64Service", BaseEncoder);

export default authentication;
