import ReferenceData from "../../../constants/ReferenceData";
import { executePost } from "../../../utils/appUtils";

interface ExistingUser {
    context: string;
    destinationUrl: string;
    errors: boolean;
    flowName: string;
    message: string;
    state: string;
}
type GetExistingUserSuccessResponse = ExistingUser | undefined;

export const getExistingUserOTS = (data, skipExperian, referrerParam) => {
    const modifiedUrl = `${ReferenceData.GET_EXISTING_USER_OTS}${skipExperian}${referrerParam}`;
    const getExistingUserSuccess = (results): GetExistingUserSuccessResponse => {
        if (results?.data) {
            return results.data;
        }
    };

    return executePost(modifiedUrl, "getExistingUserFault", data)
        .then(getExistingUserSuccess)
        .catch((error: unknown) => {
            throw error;
        });
};
