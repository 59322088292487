import React from "react";

import PropTypes from "prop-types";

import TranslationService from "../../../services/translationService";
import ValidationMessagesComponent from "../../registration/ValidationMessagesComponent";

export default class ChangeUsernameComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            cancel: this.props.cancel,
            updateUserName: this.props.updateUserName,
            value: this.props.componentValue,
            isFocus: false,
            isTouched: true,
            isDirty: true,
            isValid: false,
            errors: [],
            setErrors: false
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    //close modal
    handleClickCancel = () => {
        this.props.cancel("username");
    };

    //close modal, saving values
    handleClickUpdate = () => {
        this.props.updateUserName("profile", this.state.isValid, this.state.value);
    };

    /*-- Validations --*/

    handleFocus = () => {
        //Desactivate the red class
        this.setState({
            setErrors: false
        });
    };

    handleBlur = () => {
        //activate the red class
        this.setState({
            setErrors: true
        });
    };

    handleChange = (e) => {
        const currentValue = e.target.value;

        //this will not let updated bigger than a length size
        if (currentValue.length <= 64) {
            this.updateValue(currentValue);
        }

        this.validateValue(currentValue);
    };

    validateValue = (currentValue) => {
        const errorList = [];

        const noSpacePattern = /^\S*$/;
        const minLengthPattern = /^[\s\S]{6,}$/;
        const usernameNumberPattern = /\d{1}/;
        const letterPattern = /(.*[A-Za-z]){3}/i;
        const allowedSpecialCharactersPattern = /^[@_.A-Za-z0-9-]+$/;

        //clean on start
        this.setState({
            isValid: true,
            errors: errorList
        });

        //validate on the fly
        if (currentValue.length < 1 || currentValue === "") {
            errorList.push(this.state.translations.usernameRequired); //"Username is required"
            this.setState({
                isValid: false,
                errors: errorList
            });
        }

        if (!minLengthPattern.test(currentValue)) {
            errorList.push(this.state.translations.usernameMinLength); //"Must be at least 6 characters"
            this.setState({
                isValid: false,
                errors: errorList
            });
        }

        if (!letterPattern.test(currentValue)) {
            //3 letters pattern

            errorList.push(this.state.translations.usernameLetterPattern); //"Must include at least 3 letters"
            this.setState({
                isValid: false,
                errors: errorList
            });
        }

        if (!usernameNumberPattern.test(currentValue)) {
            //1 digit pattern

            errorList.push(this.state.translations.usernameNumberPattern); //"Must include at least 1 number"
            this.setState({
                isValid: false,
                errors: errorList
            });
        }

        if (!noSpacePattern.test(currentValue)) {
            errorList.push(this.state.translations.usernameNoSpace); //"Username may not include spaces"
            this.setState({
                isValid: false,
                errors: errorList
            });
        }

        if (
            currentValue &&
            currentValue.length > 0 &&
            !allowedSpecialCharactersPattern.test(currentValue)
        ) {
            errorList.push(this.state.translations.usernameAllowedSpecialCharacters); //"Invalid username"
            this.setState({
                isValid: false,
                errors: errorList
            });
        }
    };

    updateValue = (value) => {
        this.setState({
            value: value
        });
    };

    render() {
        if (!this.state.translations) {
            return null;
        }

        return (
            <section>
                {/* this.prop.saveStatus!=null ? <div className="form-group has-error">
                        <span className="help-block"> {{  saveStatus | translate:saveStatusParams}}
                        </span>
                    </div> : null
                    */}

                <div className="modal-header">
                    <div className="modal-title">
                        {" "}
                        {this.state.translations.profile.login.header.changeUsername}
                    </div>
                </div>
                <div className="modal-body clearfix profile">
                    <div className="modal-well with-background">
                        <form role="form" name="credentialForm" autoComplete="off" noValidate>
                            <div
                                className={
                                    !this.state.isValid && this.state.setErrors
                                        ? "form-group has-error"
                                        : "form-group"
                                }
                            >
                                <label htmlFor="usernameId" className="control-label">
                                    {this.state.translations.profile.login.label.username}
                                </label>
                                {/* Screen reader data, it was on the original one */}
                                <span className="sr-only" id="userNameRules">
                                    {this.state.translations.usernameNoSpace}.
                                    {this.state.translations.usernameMinLength}.
                                    {this.state.translations.usernameLetterPattern}.
                                    {this.state.translations.usernameNumberPattern}.
                                </span>

                                <input
                                    type="text"
                                    className="form-control"
                                    id="usernameId"
                                    name="username"
                                    required="true"
                                    value={this.state.value}
                                    maxLength="63"
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                    aria-describedby="userNameRules"
                                />

                                {/* Error Messages */}
                                <ValidationMessagesComponent
                                    errors={this.state.errors}
                                    inValid={!this.state.isValid}
                                ></ValidationMessagesComponent>

                                {this.state.isValid && (
                                    <p className="success">
                                        {this.state.translations.usernameSuccess}
                                    </p>
                                )}

                                <span id="usernameError" className="help-block">
                                    {" "}
                                    {this.state.usernameError}{" "}
                                </span>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        onClick={this.handleClickCancel}
                        className="btn btn-link"
                        id="updateUserNameId"
                    >
                        {" "}
                        {this.state.translations.preference.button.cancel}{" "}
                    </button>
                    <button onClick={this.handleClickUpdate} className="btn btn-primary">
                        {" "}
                        {this.state.translations.preference.button.save}{" "}
                    </button>
                </div>
            </section>
        );
    }

    componentDidMount() {
        this.getTranslations();

        this.updateValue(this.props.componentValue);
        this.validateValue(this.props.componentValue);
    }
}

ChangeUsernameComponent.propTypes = {
    cancel: PropTypes.func,
    updateUserName: PropTypes.func,
    componentValue: PropTypes.string
};
