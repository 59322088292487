import axios from "axios";

//TODO: Need to add annotation for this function once the API is ready

const submitIDProofAnswersApi =
    "/participant-web-services/rest/partialauth/idProof/submitIDProofData";

export const submitIDProofAnswers = async (isOptOutParams) => {
    try {
        const response = await axios.post(
            `${window.__baseApiUrl__}${submitIDProofAnswersApi}`,
            { optOut: isOptOutParams },
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );
        return response.data;
    } catch (error) {
        console.log("Errororr", error);
        return error;
    }
};
