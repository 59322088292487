import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslations } from "../../../queries";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { IDPROOF_WITH_MAILPIN, IDPROOF_ERROR } from "../../../routes";
import { retrieveIDProofQueries } from "./services/retrieveIDProof";
import { submitIDProofAnswers } from "./services/submitIDProofQuestions";
import { QUERY_KEYS } from "../../../queries/constants/index";

interface IdProofingQueryTranslations {
    idProofQueDesc: string;
    idProofQueHeading: string;
    idProofSubmit: string;
    skipPin: string;
}
interface Option {
    id: string;
    option: string;
    selected: boolean;
}
interface idProofQuestion {
    displayQuestion: string;
    id: string;
    multiSelect: boolean;
    options: Option[];
}

type SubmittedValue = {
    id: string;
};

interface SelectedAnswerValue {
    [key: number]: string;
}
interface MyComponentProps {
    flowName: string;
    idProofQuestion: idProofQuestion[];
}
//TODO: Need to remove the hardcoded data and integrate the API
const idProofQuestion = [
    {
        id: "1",
        displayQuestion:
            "Which of the following is the highest level of education you have completed?  If there is not a matched educational level, please select 'NONE OF THE ABOVE'.",
        options: [
            {
                id: "1",
                option: "HIGH SCHOOL DIPLOMA",
                selected: false
            },
            {
                id: "2",
                option: "SOME COLLEGE",
                selected: false
            },
            {
                id: "3",
                option: "BACHELOR DEGREE",
                selected: false
            },
            {
                id: "4",
                option: "GRADUATE DEGREE",
                selected: false
            },
            {
                id: "5",
                option: "NONE OF THE ABOVE/DOES NOT APPLY",
                selected: false
            }
        ],
        multiSelect: false
    },
    {
        id: "2",
        displayQuestion: "Please select the county for the address you provided.",
        options: [
            {
                id: "1",
                option: "MORTON",
                selected: false
            },
            {
                id: "2",
                option: "KINGMAN",
                selected: false
            },
            {
                id: "3",
                option: "HODGEMAN",
                selected: false
            },
            {
                id: "4",
                option: "LYON",
                selected: false
            },
            {
                id: "5",
                option: "NONE OF THE ABOVE/DOES NOT APPLY",
                selected: false
            }
        ],
        multiSelect: false
    },
    {
        id: "3",
        displayQuestion:
            "According to your credit profile, you may have opened an auto loan in or around August 2022. Please select the lender for this account. If you do not have such an auto loan, select 'NONE OF THE ABOVE/DOES NOT APPLY'.",
        options: [
            {
                id: "1",
                option: "GEC AUTO LEASE",
                selected: false
            },
            {
                id: "2",
                option: "MERCANTILE BANK",
                selected: false
            },
            {
                id: "3",
                option: "COBALT FINANCE",
                selected: false
            },
            {
                id: "4",
                option: "NISSAN MOTOR ACCEPTANCE",
                selected: false
            },
            {
                id: "5",
                option: "NONE OF THE ABOVE/DOES NOT APPLY",
                selected: false
            }
        ],
        multiSelect: false
    },
    {
        id: "4",
        displayQuestion:
            "According to our records, you graduated from which of the following High Schools?",
        options: [
            {
                id: "1",
                option: "REDWATER HIGH SCHOOL",
                selected: false
            },
            {
                id: "2",
                option: "BIG BEND HIGH SCHOOL",
                selected: false
            },
            {
                id: "3",
                option: "TAHQUITZ HIGH SCHOOL",
                selected: false
            },
            {
                id: "4",
                option: "NEW WORLD SCHOOL OF THE ARTS",
                selected: false
            },
            {
                id: "5",
                option: "NONE OF THE ABOVE/DOES NOT APPLY",
                selected: false
            }
        ],
        multiSelect: false
    },
    {
        id: "5",
        displayQuestion:
            "Which of the following institutions do you have a bank account with? If there is not a matched bank name, please select 'NONE OF THE ABOVE'.",
        options: [
            {
                id: "1",
                option: "ACCORD FINANCIAL",
                selected: false
            },
            {
                id: "2",
                option: "HALIFAX SAVINGS AND LOAN",
                selected: false
            },
            {
                id: "3",
                option: "AMERICAN BANK",
                selected: false
            },
            {
                id: "4",
                option: "WINTRUST FINANCIAL",
                selected: false
            },
            {
                id: "5",
                option: "NONE OF THE ABOVE/DOES NOT APPLY",
                selected: false
            }
        ],
        multiSelect: false
    }
];
export const IdProofQueryComponent = ({ flowName }: MyComponentProps) => {
    const [selectedAnswerValue, setSelectedAnswerValue] = useState<SelectedAnswerValue>({});
    const [submittedValue, setSubmittedValue] = useState<SubmittedValue[]>([]);
    const [isOtsUser, setOtsUser] = useState<string>("OTS");
    const {
        formState: { isSubmitting }
    } = useForm();
    const { idProofQueDesc, idProofQueHeading, skipPin, idProofSubmit } =
        useTranslations<IdProofingQueryTranslations>();
    const { data: IDProofQuestionData, isLoading: idProofQuesLoading } = useQuery({
        queryKey: [QUERY_KEYS.GET_RETRIEVE_IDPROOF_QUERIES],
        queryFn: () => retrieveIDProofQueries()
    });

    const navigate = useNavigate();
    useEffect(() => {
        console.log(IDProofQuestionData, idProofQuesLoading);
        setOtsUser("OTS"); // Need This state when we get the response from the submit question API
    }, [IDProofQuestionData, idProofQuesLoading]);

    const handleIDProofOptionSelection = useCallback(
        (displayQuestion: idProofQuestion, qusOptions: Option) => {
            setSelectedAnswerValue((prevAnswer) => {
                return {
                    ...prevAnswer,
                    [displayQuestion.id]: qusOptions.id
                };
            });
            displayQuestion.options.forEach((origOptions) => {
                origOptions.selected = origOptions.id === qusOptions.id;
            });

            const index = submittedValue?.findIndex((item) => item.id === displayQuestion.id);
            if (index !== -1) {
                submittedValue[index] = displayQuestion;
            } else {
                setSubmittedValue([...submittedValue, displayQuestion]);
            }
        },
        [submittedValue]
    );
    const handleSubmit = async () => {
        event?.preventDefault();
        console.log(flowName);
        const idProofSelDataObj = {
            idProofData: {
                idProofSelData: submittedValue
            }
        };
        try {
            const result = await submitIDProofAnswers(idProofSelDataObj);
            if (result) {
                if (result.status == "fail" && result.errorCode != null) {
                    if (isOtsUser) {
                        navigate(IDPROOF_ERROR, {
                            replace: true,
                            state: { errorCode: "idProofOTS" }
                        });
                    } else {
                        navigate(IDPROOF_WITH_MAILPIN, {
                            replace: true,
                            state: { optOut: "optOut-Query" }
                        });
                    }
                } else {
                    if (result.flowName === "otsAccountSetup") {
                        //Need to integrate redirect service
                    } else {
                        //Need to integrate redirect service
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const onOptOutClick = () => {
        navigate("/idProof/mailpin", { replace: true, state: { optOut: "optOut-Query" } });
    };
    return (
        <div className="container row">
            {isSubmitting && <div className="loader"></div>}
            <div className="row">
                <div className="registration col-xs-8 col-xs-offset-5">
                    <header className="contained-form-header">
                        <h1>{idProofQueHeading}</h1>
                        <p>{idProofQueDesc}</p>
                    </header>

                    <div className="tab-content hidden-xs">
                        <div role="tabpanel" className="tab-pane active" id="registrationContent">
                            <form role="form" id="ProofingQueryForm" onSubmit={handleSubmit}>
                                <div className="id-query-align">
                                    {idProofQuestion.map((displayQuestion, questionIndex) => (
                                        <React.Fragment key={displayQuestion.id}>
                                            <label
                                                key={displayQuestion.id}
                                                className="id-query-margin"
                                            >
                                                {displayQuestion.displayQuestion}
                                            </label>
                                            {displayQuestion.options.map((qusOptions, index) => (
                                                <>
                                                    <label
                                                        key={qusOptions.id}
                                                        className="id-query-margin"
                                                        style={{ fontWeight: "normal" }}
                                                        htmlFor={`idProof-option-${questionIndex}-${index}`}
                                                    >
                                                        <input
                                                            id={`idProof-option-${questionIndex}-${index}`}
                                                            type="radio"
                                                            name={`selectedIdProofOption${questionIndex}`}
                                                            value={JSON.stringify(qusOptions)}
                                                            checked={
                                                                selectedAnswerValue[
                                                                    displayQuestion.id
                                                                ] == qusOptions.id
                                                            }
                                                            onChange={() =>
                                                                handleIDProofOptionSelection(
                                                                    displayQuestion,
                                                                    qusOptions
                                                                )
                                                            }
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                        {qusOptions.option}
                                                    </label>
                                                </>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </div>

                                <div className="text-right margin-top-default">
                                    {isOtsUser && (
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            id="idProofQueriesOptout"
                                            onClick={onOptOutClick}
                                        >
                                            {skipPin}
                                        </button>
                                    )}
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={isSubmitting}
                                        id="idProofQueriesSubmit"
                                    >
                                        {idProofSubmit}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default IdProofQueryComponent;
