const oauthLoginTemplate = `
<div class="col-xs-12 col-sm-6 col-sm-push-4 login-form" ng-style="{top: containerTop}">
    <div class="prelogin-pod login-pod">
        <div ng-if="authentication.errorMessage !='' && authentication.errorMessage!=null && authentication.errorMessage!='undefined'"
            class="form-group has-error" id="screenReader" tabindex="0">
            <span id="helpBlock" class="help-block">
                {{("logon." + authentication.errorMessage) | translate:authentication.errorMessageParams}}
            </span>
        </div>
        <h2 class="flush-top">{{"logon.logonTitle" | translate}}</h2>

        <form role="form" id="authenticate" autocomplete="off">
            <div class="form-group">
                <label for="usernameInput" class="sr-only">{{"logon.username" | translate}}</label>

                <div class="input-group">
                    <div class="input-group-addon"><span class="em-user"></span></div>
                    <input type="text" class="form-control" id="usernameInput"
                        placeholder="{{'logon.username' | translate}}" data-ng-model="credentials.username"
                        autocomplete="off" />
                </div>
                <!--//input-group//-->
            </div>
            <div class="form-group">
                <label for="passwordInput" class="sr-only">{{"logon.password" | translate}}</label>

                <div class="input-group">
                    <div class="input-group-addon"><span class="em-lock"></span></div>
                    <input maxlength="63" type="password" class="form-control" id="passwordInput"
                        placeholder="{{'logon.password' | translate}}" data-ng-model="credentials.password"
                        autocomplete="off" />
                </div>
                <!--//input-group//-->
            </div>

            <a ui-sref="loginHelp" class="text-right center-block margin-bottom-default" title="Need help logging in?"
                data-ng-click="credentials.password='';" tabindex="0">
                {{ "logon.loginHelp" | translate }}
            </a>

            <div class="form-group clearfix">
                <pw-loading-button type='submit' id="submit" form="authenticate" disabled-loading="loggingIn"
                    class="btn-primary btn-lg btn-block text-center" default-text="Sign In" action="oauthAuthenticate()"
                    processing-text="Signing In">
                </pw-loading-button>
            </div>

        </form>
        <form id="portalRedirectForm" method="post">
            <input type="hidden" id="synctoken" value="">
            <input type="hidden" id="accu" value="">
            <input type="hidden" id="gaId" value="">
        </form>
        <!-- <a ui-sref="register.withoutPin" class="btn btn-success btn-lg btn-block text-center "
                               ng-if="accu.toLowerCase() !== 'apple'" ng-click="registerButtonClick()">{{ "logon.register" | translate }}</a>

                            <a class="btn btn-success btn-lg btn-block text-center " ng-if="accu.toLowerCase() === 'apple'"
                               ng-click="redirectAppleRegURL()">Register</a>-->

    </div>
</div>
`;
export default oauthLoginTemplate;
