import React from "react";
import RegisterCredentials from "./RegisterCredentials";
import { useForm } from "react-hook-form";
import SubmitButton from "./SubmitButton";
import { useTranslations } from "../../queries";
import { useMutation } from "@tanstack/react-query";
import { registerUser } from "../../services/accountSetup/accountSetupService";
import type { RegisterFields, SetupTranslations } from "./types";
import useRedirect from "../../hooks/useRedirect";
import { RedirectData } from "../../services/redirect/types";

const RegisterInfo = () => {
    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
        trigger
    } = useForm<RegisterFields>({
        criteriaMode: "all",
        mode: "all"
    });
    const redirect = useRedirect();
    const { accountSetup }: { accountSetup: SetupTranslations } = useTranslations();
    const mutation = useMutation({
        mutationFn: registerUser,
        onSuccess: (data: RedirectData) => redirect(data)
    });

    const handleRegisterUser = (data) => {
        const flowName = "registrationFlow";
        const params = {
            username: data.username,
            password: data.password,
            flowName
        };
        mutation.mutate(params);
    };

    const showSpinner = mutation.status === "pending";

    return (
        <div className="registration-container">
            <div className="form registration">
                <header className="contained-form-header">
                    <h1>{accountSetup.usernamePasswordTitle}</h1>
                </header>

                <div className="inner-container with-padding with-shadow">
                    <form
                        role="form"
                        id="registration-form"
                        name="accountSetupForm"
                        onSubmit={handleSubmit(handleRegisterUser)}
                        noValidate
                        autoComplete="off"
                    >
                        <RegisterCredentials
                            register={register}
                            getValues={getValues}
                            trigger={trigger}
                            errors={errors}
                        />

                        <div className="form-group clearfix">
                            <SubmitButton showSpinner={showSpinner} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RegisterInfo;
