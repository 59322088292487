/* eslint-disable react/prop-types */
import React from "react";

import AccessibilityUtil from "core-ui/client/src/app/core/util/AccessibilityUtil";
import PropTypes from "prop-types";

const AccountRecoveryErrorMessages = ({ errorsLength }) => {
    const focusOnErrorInput = function (e, elementId) {
        AccessibilityUtil.focusOnErrorInput(e, elementId);
    };
    const enableScreenReaderErrors = function (e) {
        AccessibilityUtil.enableScreenReaderErrors(e);
    };
    return (
        <div>
            <a
                href="#"
                id="screenReader"
                onClick={(event) => enableScreenReaderErrors(event)}
                className="sr-only"
            >
                There were {errorsLength} errors in the information you submitted. Review errors.
            </a>
            <div id="screenReaderErrorMessages" className="sr-only">
                <a href="#" onClick={(event) => focusOnErrorInput(event, "acPasswordInput")}></a>
                <a
                    href="#"
                    onClick={(event) => focusOnErrorInput(event, "confirmPasswordInput")}
                ></a>
            </div>
        </div>
    );
};
AccountRecoveryErrorMessages.propTypes = {
    errorsLength: PropTypes.number
};

export default AccountRecoveryErrorMessages;
