import axios from "axios";
import type { AxiosResponse } from "axios";

interface ApiResponse {
    accessPortalGroups: string[];
    contactNumber: number;
    destinationUrl: string;
    errorCode: number;
    errorMessage: string;
    idProofingQuestions: {
        displayQuestion: string;
        id: string;
        multiSelect: boolean;
        options: {
            id: string;
            option: string;
            selected: boolean;
        };
    };
    languageCode: number;
    liatCode: number;
    state: string;
    status: string;
}
const retrieveIDProofQueriesApi =
    "/participant-web-services/rest/partialauth/idProof/retrieveIDProofingData";
export const retrieveIDProofQueries = async () => {
    try {
        const response: AxiosResponse<ApiResponse> = await axios.get(
            `${window.__baseApiUrl__}${retrieveIDProofQueriesApi}`
        );
        console.log("response", response);
        return response.data;
    } catch (error) {
        return error;
    }
};
