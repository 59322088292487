"use strict";

const AppleRegSplashController = function ($sce, $scope, $location) {
    const shost = $location.host();
    const prodSearch = ["myapple401k", "proda", "prodb"];
    let prodUrl = false;
    const url = prodUrl
        ? "https://hrweb.apple.com/US/en/page/82"
        : "https://hrweb-uat.apple.com/US/en/page/82";

    prodSearch.forEach(function (search) {
        if (shost.indexOf(search) > -1) prodUrl = true;
    });

    $scope.AppleHrUrl = $sce.getTrustedUrl($sce.trustAsUrl(url));
};

AppleRegSplashController.$inject = ["$sce", "$scope", "$location"];
export default AppleRegSplashController;
