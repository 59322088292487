/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { getTranslationsService } from "core-ui/client/react/core/services/translationService";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import accountRecoveryEvents from "../../events/AccountRecoveryEvents";
import ReferenceData from "../../constants/ReferenceData";
import { getUsername } from "../../services/accountRecovery/AccountRecoveryService";
/**
 * Angular to react version
 * @param {*} param0
 * @returns
 */

const AccountRecoveryConfirmation = () => {
    const TRANSLATIONS_QUERY_KEY = ReferenceData.QUERY_KEYS.TRANSLATIONS;
    const { data: translations } = useQuery({
        queryKey: [TRANSLATIONS_QUERY_KEY],
        queryFn: getTranslationsService,
        staleTime: ReferenceData.DEFAULT_STALE_TIME
    });
    const [userInfo, setUserInfo] = useState({});

    const confirmClick = (e) => {
        e.preventDefault();
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_LINK,
            selection: accountRecoveryEvents.CONFIRM_CLICK
        });
        window.location.href = "#/login";
    };

    const getUserNameInfo = async () => {
        const data = await getUsername();
        if (data !== null && data.status == "successful" && data.username !== null) {
            setUserInfo(data);
        }
    };

    useEffect(() => {
        getUserNameInfo();
    });

    return (
        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
            <header className="contained-form-header">
                <h1>{translations?.loginHelp}</h1>
            </header>
            <div className="inner-container with-padding with-shadow">
                <form
                    role="form"
                    id="accountRecoveryConfirmForm"
                    name="accountRecoveryConfirmForm"
                    noValidate
                    autoComplete="off"
                >
                    <div className="form-group">
                        <label htmlFor="username">{translations?.username}</label>
                        <input
                            type="text"
                            className="form-control form-disabled"
                            id="username"
                            name="username"
                            value={userInfo?.username}
                            readOnly
                        />
                    </div>
                    <div>
                        <div>
                            <strong>{translations?.confirmationHeader}</strong>
                        </div>
                        <div>{translations?.confirmationContent}</div>
                        <br />
                        <p>
                            <a href="home" onClick={(e) => confirmClick(e)}>
                                {translations?.confirmationLink}
                            </a>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
};

AccountRecoveryConfirmation.propTypes = {};

export default AccountRecoveryConfirmation;
