/* eslint-disable react/prop-types */
import React from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import DateUtil from "core-ui/client/src/app/DateUtil";
import PropTypes from "prop-types";

import TranslationService from "../../services/translationService";

const DATE_FORMAT = "M/D/YYYY";
const FULL_DATE_FORMAT = "M/D/YYYY, hh:mm:ss a";

export default class AuthorizedDevicesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            isDevicesListOpen: false,
            devices: props.devices
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    handleToggleDevices = () => {
        if (this.state.isDevicesListOpen) {
            this.props.eventbus.dispatch(this.props.events.LESS_DEVICES_COLLAPSE, this);
        } else {
            this.props.eventbus.dispatch(this.props.events.MORE_DEVICES_EXPAND, this);
        }
        this.setState((state) => ({ isDevicesListOpen: !state.isDevicesListOpen }));
    };

    handleKeyboardToggleDevices = (event) => {
        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.button,
            output: () => this.handleToggleDevices()
        });
    };

    handleOpenAuthorizedDevicesModal = () => {
        this.props.eventbus.dispatch(this.props.events.CHANGE_UPDATE_AUTHORIZED_DEVICES, this);
        this.props.openmodaltemplateauthorizeddevices();
    };

    handleKeydownOpenAuthorizedDevicesModal = (event) => {
        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.button,
            output: () => this.handleOpenAuthorizedDevicesModal()
        });
    };

    render() {
        if (!this.state.translations) {
            return null;
        }
        const noDevices = this.props.devices.length === 0;
        return (
            <section>
                <div className="profile-information">
                    <div className="profile-information">
                        <div className="row">
                            <h2 className="col-md-12 margin-top-0">
                                {" "}
                                {this.state.translations.profile.authorizedDevices.header}{" "}
                            </h2>
                            {this.props.ispae || noDevices ? null : (
                                <div className="btn-container">
                                    <a
                                        tabIndex="0"
                                        role="button"
                                        onClick={this.handleOpenAuthorizedDevicesModal}
                                        className="btn btn-primary"
                                        aria-label={
                                            this.state.translations.profile.authorizedDevices
                                                .buttonAriaLabel
                                        }
                                        onKeyDown={this.handleKeydownOpenAuthorizedDevicesModal}
                                    >
                                        {this.state.translations.preference.button.change}
                                    </a>
                                </div>
                            )}
                        </div>
                        <ul>
                            {this.state.devices && noDevices && (
                                <section>
                                    <div>
                                        <div className="row">
                                            <div className="col.sm-12 margin-bottom-100">
                                                <p className="col-md-12 margin-top-0">
                                                    {
                                                        this.state.translations.profile
                                                            .authorizedDevices.noDevices
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}

                            {this.state.devices &&
                                this.state.devices.map((device, index) => {
                                    /* First Device from service*/
                                    if (index == 0 || this.state.isDevicesListOpen) {
                                        if (index > 0 && !this.state.isDevicesListOpen) {
                                            return;
                                        }
                                        return (
                                            <li
                                                key={device.deviceCustomName}
                                                style={{ listStyleType: "none" }}
                                            >
                                                <div>
                                                    <div className="row">
                                                        <h3 className="col-sm-12 margin-bottom-100">
                                                            {device.deviceCustomName}
                                                            {this.state.devices.length > 1 &&
                                                                index == 0 && (
                                                                    <span>
                                                                        <a
                                                                            tabIndex="0"
                                                                            role="button"
                                                                            onClick={
                                                                                this
                                                                                    .handleToggleDevices
                                                                            }
                                                                            onKeyDown={
                                                                                this
                                                                                    .handleKeyboardToggleDevices
                                                                            }
                                                                            aria-expanded={
                                                                                this.state
                                                                                    .isDevicesListOpen
                                                                            }
                                                                            aria-label={
                                                                                this.state
                                                                                    .isDevicesListOpen
                                                                                    ? this.state
                                                                                          .translations
                                                                                          .profile
                                                                                          .authorizedDevices
                                                                                          .showLessDevices
                                                                                    : this.state
                                                                                          .translations
                                                                                          .profile
                                                                                          .authorizedDevices
                                                                                          .showMoreDevices
                                                                            }
                                                                        >
                                                                            {` (${
                                                                                this.state
                                                                                    .isDevicesListOpen
                                                                                    ? "less"
                                                                                    : "more"
                                                                            } devices)`}
                                                                        </a>
                                                                    </span>
                                                                )}
                                                        </h3>
                                                    </div>

                                                    <div className="row">
                                                        <strong className="col-sm-4 text-right">
                                                            {
                                                                this.state.translations.profile
                                                                    .authorizedDevices.created
                                                            }
                                                        </strong>

                                                        <div className="col-sm-8">
                                                            {device.created ? (
                                                                <div>
                                                                    {DateUtil.getDateFormatted(
                                                                        DATE_FORMAT,
                                                                        device.created
                                                                    )}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <strong className="col-sm-4 text-right">
                                                            {
                                                                this.state.translations.profile
                                                                    .authorizedDevices.lastSignIn
                                                            }
                                                        </strong>

                                                        <div className="col-sm-8">
                                                            {device.lastSignIn ? (
                                                                <div>
                                                                    {DateUtil.getDateFormatted(
                                                                        FULL_DATE_FORMAT,
                                                                        device.lastSignIn
                                                                    )}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }
                                })}
                        </ul>
                        <hr />
                    </div>
                </div>
            </section>
        );
    }

    componentDidMount() {
        this.getTranslations();
    }
}

AuthorizedDevicesComponent.propTypes = {
    devices: PropTypes.arrayOf(
        PropTypes.shape({
            deviceCustomName: PropTypes.string,
            created: PropTypes.number,
            lastSignIn: PropTypes.number
        })
    )
};
