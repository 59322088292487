const appleRegisterSplashTemplate = `
<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-8">
    <h1>How to set up your account</h1>
  </div>
  <div class="col-md-2"></div>
</div>
<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-4">
    <div class="shadowify">
      <div style="height:270px;padding:60px 20px 0px 20px" class="inner-container with-offset with-padding text-center">
        <p style="font-size:20px;">Current Apple employees,<br />register through HRWeb</p>
        <a ng-href="{{AppleHrUrl}}" style="font-size:25px;width:100%;margin:30px 000;" class="btn btn-primary"><b>HR</b>Web</a>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="shadowify">
      <div style="height:270px;" class="inner-container with-offset with-padding text-center">
        <p style="font-size:20px;">If you're not a current Apple<br />employee, but have a balance<br />in the Apple 401(k) Plan, please<br />call Empower Retirement.</p>
        <p style="color:#008aff;font-weight:bold;font-size:28px;">1-844-277-4401</p>
        <p style="font-size:14px;">Call between 5:00 AM and 7 PM PST Monday through Friday.</p>
      </div>
    </div>
  </div>    
  <div class="col-md-2"></div>
</div>
`;
export default appleRegisterSplashTemplate;
