import React from "react";

interface SubmitButtonProps {
    showSpinner: boolean;
}

const SubmitButton = ({ showSpinner }: SubmitButtonProps) => {
    return (
        <button
            type="submit"
            id="submit"
            className={`btn btn-primary btn-block btn-lg margin-top-default ${
                showSpinner ? "is-disabled" : ""
            }`}
        >
            {!showSpinner ? (
                <span>Register</span>
            ) : (
                <span>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    Processing ...
                </span>
            )}
        </button>
    );
};

export default SubmitButton;
