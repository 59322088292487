import React from "react";
import type { RegisterOptions, UseFormRegister } from "react-hook-form";
import MaskedInput from "react-text-mask";
import type { RegisterFields } from "./types";

interface PhoneInputProps {
    name: keyof RegisterFields;
    options: RegisterOptions<RegisterFields>;
    register: UseFormRegister<RegisterFields>;
    useMask: boolean;
}

const PhoneInput = ({ register, name, options, useMask }: PhoneInputProps) => {
    return useMask ? (
        <MaskedInput
            {...register(name, options)}
            mask={["(", /\d/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
            guide={true}
            showMask={true}
            className="form-control"
            type={"tel"}
        />
    ) : (
        <input {...register(name, options)} type="tel" className="form-control" id={name} />
    );
};

export default PhoneInput;
