import React from "react";
import { useTranslations } from "../../../queries";
import { useQuery } from "@tanstack/react-query";
import { getSiteContactInfoPreLogin } from "core-ui/client/react/core/services/siteContactInfoServicePreLogin.js";
import { getDefaultAcctsCustRetrievalList } from "core-ui/client/react/core/services/defaultAcctsCustRetrievalService.js";
import { QUERY_KEYS } from "../../../queries/constants/index";

export interface ErrorComponentTranslation {
    idProofOTS: string;
    unableToContinue: string;
}
interface IdProofError {
    phone: string;
}

const IdProofError = () => {
    const { data: defaultAcctsCustRetrievalList, isLoading: accCustRetriveLoading } = useQuery({
        queryKey: [QUERY_KEYS.GET_DEFAULT_ACCTS_CUST_RETRIEVAL],
        queryFn: () => getDefaultAcctsCustRetrievalList()
    });
    const contactInfoPayload = {
        accu: defaultAcctsCustRetrievalList?.global.accuCode,
        accuSpecificPrimDb: defaultAcctsCustRetrievalList?.primaryDatabase.preLoginPrimaryDB,
        portalType: "TOLLFREE"
    };
    const { data: siteContactInfo, isLoading: loadingContactInfo } = useQuery({
        queryKey: [QUERY_KEYS.GET_SITE_CONTACT_INFO],
        queryFn: () => getSiteContactInfoPreLogin({ ...contactInfoPayload }),
        enabled: Boolean(contactInfoPayload)
    });

    const { idProofOTS, unableToContinue } = useTranslations<ErrorComponentTranslation>();
    const errorMessage = idProofOTS.replace(
        "{{phone}}",
        `${siteContactInfo?.phone ? siteContactInfo?.phone : "1-855-756-4738"}`
    );
    if (accCustRetriveLoading || loadingContactInfo) {
        return (
            <div className="loaderBackground">
                <div className="loader"></div>
            </div>
        );
    }
    return (
        <div className="container row">
            <div className="row">
                <div className="registration col-xs-6 col-xs-offset-5">
                    <header className="contained-form-header">
                        <h1>{unableToContinue}</h1>
                    </header>

                    <div className="tab-content hidden-xs">
                        <div role="tabpanel" className="tab-pane active">
                            <p dangerouslySetInnerHTML={{ __html: errorMessage ?? "" }}></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IdProofError;
