class StateChangeService {
    static goToState = (stateName, stateParams) => {
        //look for angular provided 'goToState' window-level function.
        if (window.goToState) {
            window.goToState(stateName, stateParams);
        }
    };
}

export default StateChangeService;
