import React, { MouseEvent, useContext } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";
import type { UseFormRegister } from "react-hook-form";
import { DeliveryOptionsFields } from "./types";

interface CustomToggleProps {
    dispatchAmplitudeEvent: (event: MouseEvent<HTMLElement>) => void;
    eventKey: string;
    option: { alt: string; id: string; title: string; url: string };
    register: UseFormRegister<DeliveryOptionsFields>;
    selection: string;
}

const CustomToggle = ({
    eventKey,
    selection,
    dispatchAmplitudeEvent,
    option,
    register
}: CustomToggleProps) => {
    const { activeEventKey } = useContext(AccordionContext);
    const handleAccordionToggle = useAccordionButton(eventKey, dispatchAmplitudeEvent);

    return (
        <div
            className={`accordion-header ${eventKey === activeEventKey ? "expanded" : ""}`}
            data-selection={selection}
            onClick={handleAccordionToggle}
        >
            <div className="radio-button-container">
                <input
                    id={option.id}
                    data-testid={option.id}
                    type="radio"
                    value={option.id}
                    aria-label={option.title}
                    {...register("selectedOption", { required: true })}
                />
                <span className="option-title">{option.title}</span>
            </div>
            <img src={option.url} alt={option.alt} />
        </div>
    );
};

export default CustomToggle;
