const preOauthLoginTemplate = `
<head></head>

<body>
  <div ng-controller="oauthController">
    <form role="form" name="getUrlForm-form" data-ng-init="getLginURL()"></form>
  </div>
</body>
`;
export default preOauthLoginTemplate;
