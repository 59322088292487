import React, { Fragment } from "react";
import { isNull } from "lodash";
import PhoneInput from "./PhoneInput";
import { useTranslations } from "../../queries";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import accountSetupEvents from "../../../accountSetup/events/AccountSetupEvents";
import useContactDetails from "../../queries/useContactDetails/useContactDetails";
import type { AllFlowsContactInfoUpdateProps, Translations } from "./types";

const AllFlowsContactInfoUpdate = ({
    register,
    trigger,
    errors,
    saveError
}: AllFlowsContactInfoUpdateProps) => {
    const { data: contactDetails, isLoading } = useContactDetails();
    const { profile, accountSetup }: Translations = useTranslations();

    const usernameChange = (e: React.FormEvent) => {
        const target = e.target as HTMLInputElement;

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_FIELD,
            selection: accountSetupEvents.USRNM_CHANGE,
            payload: {
                username: target.value
            }
        });
    };

    if (isLoading) return <div id="spinner" className="loader"></div>;

    return (
        <Fragment>
            {!isNull(saveError) && (
                <div className="form-group has-error">
                    <span className="help-block">{saveError?.code}</span>
                </div>
            )}
            <div className={`form-group ${errors.email && "has-error"}`}>
                <label htmlFor="emailId" className="control-label">
                    {profile.personal.emailAddress}
                </label>
                <input
                    type="text"
                    {...register("email", {
                        required: accountSetup.emailRequired,
                        maxLength: 80,
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@([a-zA-Z0-9\-'`]+[.])+[a-zA-Z]{2,}$/,
                            message: accountSetup.emailFormat
                        },
                        onBlur: (e) => {
                            trigger(e.target.name);
                            usernameChange(e);
                        }
                    })}
                    name="email"
                    id="emailId"
                    className="form-control"
                />
                {errors.email?.message && <p className="error-text">{errors.email.message}</p>}
                {errors.email?.type === "maxLength" && <p>{accountSetup.emailMaxLength}</p>}
                <span role="alert" className="help-block">
                    {saveError?.email}
                </span>
            </div>

            {!isNull(contactDetails.domesticMobile) && !isNull(contactDetails.intlNumber) && (
                <div className="form-group">
                    <label htmlFor="phoneNumber" className="control-label">
                        {"phone_number_1"}
                    </label>
                </div>
            )}

            {!isNull(contactDetails.domesticMobile) && isNull(contactDetails.intlNumber) && (
                <div className="form-group">
                    <label htmlFor="phoneNumber" className="control-label">
                        {"phone_number_only"}
                    </label>
                </div>
            )}

            {!isNull(contactDetails.domesticMobile) && (
                <div className="row">
                    {isNull(contactDetails?.intlNumber) && (
                        <div className="col-sm-6">
                            <select
                                {...register("contactCountryNameD", {
                                    required: true,
                                    onBlur: (e) => trigger(e.target.name)
                                })}
                                aria-label={profile.personal.mobileContactCountryLabel}
                                className="form-control"
                                id="ContactCountryNameD"
                            >
                                {contactDetails.countryDetails.map((option) => {
                                    return (
                                        <option
                                            key={option.countryCode}
                                            value={`${option.countryCode}`}
                                            aria-label={option.countryName}
                                        >
                                            {option.countryName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}

                    {!isNull(contactDetails.intlNumber) && (
                        <div className="col-sm-6">United States</div>
                    )}

                    {contactDetails.domesticCntryCode === "US1" && (
                        <div className="col-sm-6">
                            <div className={`input-group ${errors.phoneNumberIdD && "has-error"}`}>
                                <span className="input-group-addon">{"selectedCntryPhCodeD"}</span>
                                <PhoneInput
                                    register={register}
                                    name={"phoneNumberIdD"}
                                    options={{
                                        onBlur: (e) => trigger(e.target.name),
                                        pattern: {
                                            value: /^\d{10}$/,
                                            message: accountSetup.phoneNumberTenDigits
                                        }
                                    }}
                                    useMask
                                />
                            </div>
                            {errors.phoneNumberIdD?.message && (
                                <p className="error-text">{errors.phoneNumberIdD.message}</p>
                            )}
                        </div>
                    )}

                    {contactDetails.domesticCntryCode !== "US1" && (
                        <div className="col-sm-6">
                            <div className={`input-group ${errors.intlNumberIdD && "has-error"}`}>
                                <span className="input-group-addon">{"selectedCntryPhCodeD"}</span>
                                <PhoneInput
                                    register={register}
                                    name={"intlNumberIdD"}
                                    options={{
                                        required: accountSetup.phoneNumberRequired,
                                        onBlur: (e) => trigger(e.target.name),
                                        maxLength: {
                                            value: 20,
                                            message: accountSetup.intlPhoneNumberMaxLength
                                        },
                                        pattern: /[^0-9]/g
                                    }}
                                    useMask={false}
                                />
                            </div>
                            {errors.intlNumberIdD?.message && (
                                <p className="error-text">{errors.intlNumberIdD.message}</p>
                            )}
                        </div>
                    )}
                    <span id="phoneNumberError" className="help-block">
                        {saveError?.phoneNumber}
                    </span>
                </div>
            )}

            {!isNull(contactDetails.domesticMobile) && !isNull(contactDetails.intlNumber) && (
                <div className="form-group">
                    <label htmlFor="phoneNumber" className="control-label">
                        {"phone_number_2"}
                    </label>
                </div>
            )}

            {isNull(contactDetails.domesticMobile) && !isNull(contactDetails.intlNumber) && (
                <div className="form-group">
                    <label htmlFor="phoneNumber" className="control-label">
                        {"phone_number_only"}
                    </label>
                </div>
            )}

            <div className="row">
                <div className="col-sm-6">
                    <select
                        {...register("contactCountryNameI", {
                            required: true,
                            onBlur: (e) => trigger(e.target.name)
                        })}
                        className="form-control"
                        id="contactCountryNameI"
                    >
                        {contactDetails.countryDetails.map((option) => {
                            return (
                                <option
                                    key={option.countryCode}
                                    value={`${option.countryCode}`}
                                    aria-label={option.countryName}
                                >
                                    {option.countryName}
                                </option>
                            );
                        })}
                    </select>
                </div>

                {contactDetails.intCntryCode === "US1" && (
                    <div className="col-sm-6">
                        <div className={`input-group ${errors.phoneNumberIdI && "has-error"}`}>
                            <span className="input-group-addon">{"selectedCntryPhCodeI"}</span>
                            <PhoneInput
                                register={register}
                                name={"phoneNumberIdI"}
                                options={{
                                    onBlur: (e) => trigger(e.target.name),
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: accountSetup.phoneNumberTenDigits
                                    }
                                }}
                                useMask
                            />
                        </div>
                        {errors.phoneNumberIdI?.message && (
                            <p className="error-text">{errors.phoneNumberIdI.message}</p>
                        )}
                        <span id="phoneNumberError" className="help-block">
                            {saveError?.phoneNumber}
                        </span>
                    </div>
                )}

                {contactDetails.intCntryCode !== "US1" && (
                    <div className="col-sm-6">
                        <div className={`input-group ${errors.intlNumberIdI && "has-error"}`}>
                            <span className="input-group-addon">{"selectedCntryPhCodeI"}</span>
                            <PhoneInput
                                register={register}
                                name={"intlNumberIdI"}
                                options={{
                                    onBlur: (e) => trigger(e.target.name),
                                    required: accountSetup.phoneNumberRequired,
                                    maxLength: {
                                        value: 20,
                                        message: accountSetup.intlPhoneNumberMaxLength
                                    },
                                    pattern: /[^0-9]/g
                                }}
                                useMask={false}
                            />
                        </div>
                        {errors.intlNumberIdI?.message && (
                            <p className="error-text">{errors.intlNumberIdI.message}</p>
                        )}
                        <span id="phoneNumberError" className="help-block">
                            {saveError?.phoneNumber}
                        </span>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default AllFlowsContactInfoUpdate;
