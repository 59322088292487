import React from "react";

import PropTypes from "prop-types";

import TranslationService from "../../../services/translationService";

export default class ChangeHomeAddressComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: null,
            cancel: this.props.cancel,
            updateHomeAddress: this.props.updateHomeAddress,
            saveStatus: this.props.saveStatus,
            saveStatusParams: this.props.saveStatusParams,
            stline1: this.props.stline1,
            stline2: this.props.stline2,
            city: this.props.city,
            stateOptions: this.props.stateOptions,
            zipcode: this.props.zipcode,
            selectedStateValue: this.props.selectedStateValue,
            selectedCountryValue: this.props.selectedCountryValue,
            countryOptions: this.props.countryOptions
        };
    }

    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    /*-- Handle red class for errors --*/

    handleChange = (e) => {
        const currentValue = e.target.value;
        const field = e.target.name;
        this.updateValue(currentValue, field);
    };

    getValueFromCode = (source, selectedCode) => {
        for (let i = 0; i < source.length; i++) {
            if (source[i].code === selectedCode) {
                return source[i];
            }
        }

        return "";
    };

    updateValue = (currentValue, field) => {
        if (field === "state") {
            this.setState((state) => {
                state.selectedStateValue = this.getValueFromCode(
                    this.state.stateOptions,
                    currentValue
                );
                return state;
            });
        } else if (field === "country") {
            this.setState((state) => {
                state.selectedCountryValue = this.getValueFromCode(
                    this.state.countryOptions,
                    currentValue
                );
                return state;
            });
        } else {
            this.setState((state) => {
                state[field] = currentValue;
                return state;
            });
        }
    };

    handleFocus = () => {};

    handleBlur = () => {};

    //close modal
    handleClickCancel = () => {
        this.props.cancel("homeAddress");
    };

    handleUpdateHomeAddress = () => {
        const updateHomeAddressData = {
            stline1: this.state.stline1,
            stline2: this.state.stline2,
            city: this.state.city,
            state: this.state.selectedStateValue.code,
            zipcode: this.state.zipcode,
            country: this.state.selectedCountryValue.code
        };
        this.props.updateHomeAddress("profile", true, updateHomeAddressData);
    };

    componentDidUpdate(nextProps) {
        if (this.state.saveStatus !== nextProps.saveStatus) {
            this.setState({
                saveStatus: nextProps.saveStatus
            });
        }
        if (this.state.saveStatusParams !== nextProps.saveStatusParams) {
            this.setState({
                saveStatusParams: nextProps.saveStatusParams
            });
        }
    }

    render() {
        if (!this.state.translations) {
            return null;
        }

        return (
            <section>
                {/*
                REACT WIP
                todo: validation, check original fields, for specifironchange and patterns.
                todo:submit function
                */}

                <div className="modal-header" data-testid="change-home-address-component">
                    <div className="modal-title">
                        {this.state.translations?.profile?.login?.header?.changeHomeAddress}
                    </div>
                </div>

                <div className="modal-body clearfix profile">
                    <div className="modal-well with-background">
                        {this.state.saveStatus != null ? (
                            <div className="form-group has-error">
                                <span className="help-block">
                                    {TranslationService.translate(
                                        this.state.translations[this.state.saveStatus],
                                        this.state.saveStatusParams
                                    )}
                                </span>
                            </div>
                        ) : null}

                        <form role="form" name="homeAddressForm" autoComplete="off" noValidate>
                            {/* Address line 1 - stline1 */}
                            <div className="form-group">
                                {" "}
                                {/* "fieldOnBlur('stline1')" */}
                                <label htmlFor="stline1Id" className="control-label">
                                    {
                                        this.state.translations?.profile?.login?.label?.homeAddress
                                            ?.stline1
                                    }
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="stline1Id"
                                    name="stline1"
                                    required={true}
                                    value={this.state.stline1}
                                    maxLength="35"
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                />
                            </div>

                            {/* Address line 2 - stline2 */}

                            <div className="form-group">
                                {" "}
                                {/*data-ng-class="getFormGroupClass('stline2')"> */}
                                <label htmlFor="stline2Id" className="control-label">
                                    {
                                        this.state.translations?.profile?.login?.label?.homeAddress
                                            ?.stline2
                                    }
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="stline2Id"
                                    name="stline2"
                                    value={this.state.stline2}
                                    maxLength="35"
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                />
                            </div>

                            {/* City */}
                            <div className="form-group">
                                {" "}
                                {/*data-ng-class="fieldOnBlur('city')"> */}
                                {this.state.selectedCountryValue?.code === "US" ||
                                this.state.selectedCountryValue?.code === "CA" ? (
                                    <label htmlFor="cityId" className="control-label">
                                        {
                                            this.state.translations?.profile?.login?.label
                                                ?.homeAddress?.city
                                        }
                                    </label>
                                ) : (
                                    <label htmlFor="cityId" className="control-label">
                                        {
                                            this.state.translations?.profile?.login?.label
                                                ?.homeAddress?.combinedCityState
                                        }
                                    </label>
                                )}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cityId"
                                    name="city"
                                    required={true}
                                    value={this.state.city}
                                    maxLength="20"
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                />
                            </div>

                            {/* State */}
                            {(this.state.selectedCountryValue?.code === "US" ||
                                this.state.selectedCountryValue?.code === "CA") && (
                                <div className="form-group">
                                    {" "}
                                    {/* data-ng-class="fieldOnBlur('state')" */}
                                    <label htmlFor="state" className="control-label">
                                        {" "}
                                        {
                                            this.state.translations?.profile?.login?.label
                                                ?.homeAddress?.state
                                        }
                                    </label>
                                    <div>
                                        <select
                                            name="state"
                                            id="state"
                                            className="form-control"
                                            required={true}
                                            value={this.state.selectedStateValue?.code}
                                            onChange={this.handleChange}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                        >
                                            {this.state.stateOptions.map((opt) => (
                                                <option key={opt.code} value={opt.code}>
                                                    {opt.description}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}

                            {/* Zipcode for US */}

                            {this.state.selectedCountryValue?.code === "US" && (
                                <div className="form-group">
                                    {" "}
                                    {/* data-ng-class="fieldOnBlur('zipcode')" */}
                                    <label htmlFor="zipcodeId" className="control-label">
                                        {" "}
                                        {
                                            this.state.translations?.profile?.login?.label
                                                ?.homeAddress?.zipcode
                                        }{" "}
                                    </label>
                                    <input
                                        type="text"
                                        inputMode="numeric"
                                        className="form-control"
                                        id="zipcodeId"
                                        name="zipcode"
                                        required={true}
                                        value={this.state.zipcode}
                                        maxLength="5"
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        onBlur={this.handleBlur}
                                    />
                                </div>
                            )}

                            {/* Zipcode for Others */}
                            {!(this.state.selectedCountryValue?.code === "US") && (
                                <div className="form-group">
                                    {" "}
                                    {/* data-ng-class="getFormGroupClass('zipcode');" */}
                                    <label htmlFor="zipcodeId" className="control-label">
                                        {
                                            this.state.translations?.profile?.login?.label
                                                ?.homeAddress?.zipcode
                                        }
                                    </label>
                                    <input
                                        type="text"
                                        inputMode="numeric"
                                        className="form-control"
                                        id="zipcodeId"
                                        name="zipcode"
                                        required={true}
                                        value={this.state.zipcode}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        onBlur={this.handleBlur}
                                        maxLength="10"
                                    />
                                </div>
                            )}

                            {/* Country */}

                            <div className="form-group">
                                {" "}
                                {/*  data-ng-class="fieldOnBlur('country')" */}
                                <label htmlFor="countryId" className="control-label">
                                    {
                                        this.state.translations?.profile?.login?.label?.homeAddress
                                            ?.country
                                    }
                                </label>
                                <div className="custom-select form-control">
                                    <select
                                        name="country"
                                        id="countryId"
                                        className="form-control"
                                        required={true}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        onBlur={this.handleBlur}
                                        value={this.state.selectedCountryValue?.code}
                                    >
                                        {this.state.countryOptions?.map((opt) => (
                                            <option key={opt.code} value={opt.code}>
                                                {opt.description}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        onClick={this.handleClickCancel}
                        className="btn btn-link"
                        id="updateHomeAddressId"
                    >
                        {this.state.translations?.preference?.button?.cancel}
                    </button>
                    <button onClick={this.handleUpdateHomeAddress} className="btn btn-primary">
                        {this.state.translations?.preference?.button?.save}
                    </button>
                </div>
            </section>
        );
    }

    componentDidMount() {
        this.getTranslations();
    }
}

ChangeHomeAddressComponent.propTypes = {
    cancel: PropTypes.func,
    updateHomeAddress: PropTypes.func,
    saveStatus: PropTypes.any,
    saveStatusParams: PropTypes.any,
    stline1: PropTypes.string,
    stline2: PropTypes.string,
    city: PropTypes.string,
    stateOptions: PropTypes.any,
    zipcode: PropTypes.any,
    countryOptions: PropTypes.any,
    selectedCountryValue: PropTypes.any,
    selectedStateValue: PropTypes.any
};
