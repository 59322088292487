import React from "react";

import PropTypes from "prop-types";

import TranslationService from "../../../services/translationService";

export default class NoCodeModalComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            translations: null
        };
    }

    /**
     *  handle 'cancel' click.
     */
    onCancelClick = (e) => {
        //React function
        e.preventDefault();
        this.props.options.onCancel();
    };

    /**
     *  handle 'confirm' click.
     */
    onConfirmClick = (e) => {
        //React function
        e.preventDefault();
        this.props.options.onConfirm();
    };

    render() {
        if (!this.state.translations) {
            return null;
        }
        return (
            <div className="modal-content" data-testid="no-code-modal-component">
                <div className="modal-header">
                    <div className="modal-title">
                        {this.state.translations?.oneTimePassword?.otpNoCodeHeader}
                    </div>
                </div>
                <div className="modal-body clearfix">
                    <div className="modal-well with-background">
                        <p>{this.state.translations?.oneTimePassword?.otpNoCodeMessage}</p>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-link margin-top-default"
                        onClick={this.onCancelClick}
                    >
                        {this.state.translations?.oneTimePassword?.otpNoCodeCancelButton}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary margin-top-default"
                        onClick={this.onConfirmClick}
                    >
                        {this.state.translations?.oneTimePassword?.otpNoCodeContinueButton}
                    </button>
                </div>
            </div>
        );
    }

    /**
     * get the translations.
     */
    getTranslations = () => {
        TranslationService.getTranslations((json) => {
            this.setState({
                translations: json
            });
        });
    };

    /*#################################################################
     # LifeCycle Hooks
     #################################################################*/

    componentDidMount() {
        this.getTranslations();
    }
}

NoCodeModalComponent.propTypes = {
    options: PropTypes.object.isRequired
};
