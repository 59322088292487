import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import _findIndex from "lodash/findIndex";

const ProfileService = function ($injector, $q, dataService, TransactionAccessRetrService) {
    const service = {};
    const logger = ExternalLogger.getInstance("ProfileService");

    // Service data that we want to persist
    const data = {
        plans: null
    };

    /**
     * Get all plans for the participant.
     */
    service.getPlans = function () {
        const deferred = $q.defer();
        const planFactory = $injector.get("planFactory");

        logger.debug("getPlans()");

        if (data.plans) {
            logger.debug("{0} plans are already loaded.", [data.plans.length]);

            deferred.resolve(data.plans);
        } else {
            logger.debug("Getting plans from the PlanFactory");

            planFactory.get({}, onPlanFactoryGetSuccess, onPlanFactoryGetError);
        }

        function onPlanFactoryGetSuccess(response) {
            logger.debug("getPlans() SUCCESS. Found {0} plans.", [response.plans.length]);

            data.plans = response.plans;

            // We've saved the plans to the service, so no need to do anything
            deferred.resolve(response.plans);
        }

        function onPlanFactoryGetError(errorMessage) {
            errorHandler("getPlans", deferred, errorMessage);
        }

        return deferred.promise;
    };

    /**
     * Load beneficiaries for all plans.
     */
    service.loadPlansWithBeneficiaries = function (indid) {
        const deferred = $q.defer();
        logger.debug("loadPlansWithBeneficiaries()");

        service.getPlans().then(getPlansSuccess, onLoadPlansWithBeneficiariesError);

        function getPlansSuccess(plans) {
            getBeneficiaries(plans, 0, indid).then(
                onGetBeneficiariesSuccess,
                onLoadPlansWithBeneficiariesError
            );

            function onGetBeneficiariesSuccess(beneficiaries) {
                let index = 0;

                // The beneficiaries are in a collection, organized by group. We need to iterate through each
                // set and assign them to the proper plan.
                for (let i = 0, n = beneficiaries.length; i < n; i++) {
                    // Get the index of the plan for this group
                    index = _findIndex(plans, { gaId: beneficiaries[i].gaId });

                    if (index > -1) {
                        plans[index].beneficiaries = beneficiaries[i].beneficiaries;
                        plans[index].beneUAccess = beneficiaries[i].beneUAccess;
                        logger.debug(
                            "loadBeneficiaries() SUCCESS. Found {0} beneficiaries for plan {1}",
                            [plans[index].beneficiaries.length, plans[index].planName]
                        );
                    }
                }

                deferred.resolve(plans);
            }
        }

        function onLoadPlansWithBeneficiariesError(errorMessage) {
            errorHandler("loadPlansWithBeneficiaries", deferred, errorMessage);
        }

        return deferred.promise;
    };

    /**
     * Get the beneficiaries for a plan (using the plan's gaId).
     *
     * We fetch the beneficiaries for each plan recursively because we need to wait for each call to complete, then
     * clear out the beneficiary-ui/dataService data before calling getBeneficiaries on the next plan. Otherwise the
     * service would cache the first results. This approach was taken to avoid refactoring beneficiary-ui to cache
     * data based on the gaId as there is not time to test the impact to beneficiary-ui.
     *
     * @param   array     plans The participant's plans.
     * @param   integer   index The index of the current plan whose beneficiaries we will query.
     * @returns array           A collection of beneficiaries organized by gaId.
     */
    function getBeneficiaries(plans, index, indid) {
        const deferred = $q.defer();

        const beneficiaries = [];

        getBeneAccess(plans, indid, index).then(function (beneAccess) {
            // angular.forEach(plans, function(plan){
            const beneAccessObj = checkBeneAccess(beneAccess, plans[index].gaId);

            // Get the beneficiaries for the current plan and wait for a response
            if (beneAccessObj.beneRAccess === "true") {
                dataService.getBeneficiaries(plans[index].gaId).then(function (benes) {
                    // Clear the service data so we can get beneficiaries for the next plan
                    dataService.reset();

                    // Add the beneficiaries to the collection that will be returned
                    beneficiaries.push({
                        gaId: plans[index].gaId,
                        beneficiaries: benes,
                        beneUAccess: beneAccessObj.beneUAccess
                    });

                    // If this is the last plan, we can return the beneficiaries; otherwise we need to get beneficiaries
                    // for the next plan.
                    if (plans.length - 1 == index) {
                        deferred.resolve(beneficiaries);
                    } else {
                        // Get beneficiaries for the next plan
                        getBeneficiaries(plans, index + 1, indid).then(function (benes2) {
                            // Add the beneficiaries to the current collection and return
                            const newBenes = beneficiaries.concat(benes2);

                            deferred.resolve(newBenes);
                        });
                    }
                });
            }
            // });
        });

        return deferred.promise;
    }

    function checkBeneAccess(beneAccess, gaId) {
        let valObj = {};
        // eslint-disable-next-line no-undef
        angular.forEach(beneAccess, function (beneObj) {
            // eslint-disable-next-line no-undef
            angular.forEach(beneObj, function (value, key) {
                if (key === gaId) {
                    valObj = value;
                }
            });
        });

        return valObj;
    }

    function getBeneAccess(plans, indid, index) {
        const deferred = $q.defer();

        const beneList = [];

        // angular.forEach(plans, function(plan){
        const obj = {};
        const valObj = {};
        const txnCodeParams = {
            txnCode: "WNBEN,WUBENE",
            individualId: indid,
            groupId: plans[index].gaId
        };

        TransactionAccessRetrService.queryWithAccount(
            txnCodeParams,
            function (response) {
                if (response) {
                    valObj.gaId = plans[index].gaId;
                    valObj.beneRAccess = response.WNBEN;
                    valObj.beneUAccess = response.WUBENE;
                    obj[plans[index].gaId] = valObj;
                    beneList.push(obj);
                }
                deferred.resolve(beneList);
            },
            function (err) {
                //Error with transaction access call
                deferred.reject(err);
            }
        );
        //    });

        return deferred.promise;
    }

    /**
     * Generic error handler to log the error and pass it back to the calling function.
     *
     * @param string    fn              The name of the calling function that failed
     * @param promise   deferred        The promise that failed
     * @param string    errorMessage    The error message
     */
    function errorHandler(fn, deferred, errorMessage) {
        logger.error("{0}() FAILED. {1}", [fn, errorMessage]);

        // Pass errors back to the calling function
        deferred.reject(errorMessage);
    }

    return service;
};

ProfileService.$inject = ["$injector", "$q", "dataService", "TransactionAccessRetrService"];
export default ProfileService;
