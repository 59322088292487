const communicationPreferencesTemplate = `
<div ng-class="{'inner-container with-padding': isStandalonePage}">
<div class="modal-header">
  <div class="modal-title" id="modal-title" role="heading" aria-level="2">{{ "preference.eDelivery.modalheadline" | translate }}</div>
</div>
<div class="modal-body clearfix"  id="myModal" data-backdrop="static"  >

  <form name="eConsentForm"  data-ng-submit='updatePPTConsent(sourcepage)' autocomplete="off" novalidate >

    <div data-ng-show="saveStatus !== null && primaryPlanT !== true;" class="form-group has-error">
      <span class="help-block"> {{  saveStatus | translate:saveStatusParams}}</span>
    </div>

    <div data-ng-show="saveStatus !== null && primaryPlanT === true;" class="alert alert-warning">
      <p> {{  saveStatus | translate:saveStatusParams}}</p>
    </div>

    <table class="table">
      <thead>
          <tr>
              <th class="col-md-6">
                <strong>{{ "preference.eDelivery.label.myPlans" | translate }}</strong>
              </th>
              <th class="col-md-3"></th>
              <th class="col-md-1"></th>
            </tr>
      </thead>
      <tbody>
        <tr ng-repeat="eConsentDetail in eConsentDetails | orderBy: ['-primaryPlan', 'planName']">
            <td ng-show="eConsentDetail.statusCode==='A'">
                <div>
                  <div><label for="eConsentPlanName--{{$index}}">{{eConsentDetail.planName}}</label></div>
                  <div>{{ 'preference.eDelivery.documentsInclude' | translate:{documentTypes: eConsentDetail.docTypes} }}</div>
                </div>
            </td>
            <td class="pull-right" ng-show="eConsentDetail.statusCode==='A'">
                <div class="checkbox-inline">
                  <label for="pptConsentForElectronic--{{$index}}" data-ng-show="eConsentDetail.pinAuthCode==='U'">
                    <input type="checkbox"
                            ng-model="eConsentDetail.pptConsentForElectronic"
                            data-ng-disabled="primaryPlanT === true"
                            data-ng-show="eConsentDetail.pinAuthCode==='U'"
                            id="pptConsentForElectronic--{{$index}}"
                            name="pptConsentForElectronic--{{$index}}"
                            ng-change="updateEConsentDetail()"
                            ng-true-value="true"
                            ng-false-value="false">
                    {{ "preference.eDelivery.deliveryMethod.option5" |translate }}
                  </label>
                </div>
            </td>
            <td ng-show="eConsentDetail.statusCode==='A'"></td>
        </tr>
      </tbody>
    </table>

    <p>{{ 'preference.eDelivery.mailDeliveryInstruction' | translate }}</p>

    <div class="form-group" ng-if="email.primary && email.secondary">
      <label for="emailChoice" class="control-label">{{ "preference.eDelivery.label.email" | translate }}</label>
      <select id="emailChoice" class="form-control col-md-6" name="emailchoice" data-ng-model="eConsent.emailchoice" >
       <option ng-repeat="emailOption in email" value="{{emailOption}}">{{emailOption}}</option>
      </select>
    </div>

    <div class="form-group" ng-class="getFormGroupClass('email')"  ng-if="!(email.primary || email.secondary)">
      <label for="emailChoice" class="control-label">{{ "profile.personal.emailAddress" | translate }}</label>
      <input
        type="text"
        name="email"
        id ="emailId"
        class="form-control"
        id="personalEmailAddressId"
        data-ng-model="eConsent.emailchoice"
        data-ng-required="mustHaveEmail"
        maxlength="80"
        data-email-validator/>

      <ng-messages 
        for="getFieldError('email')" 
        data-ng-if="isDirty('email')"
      >
          <ng-message when="required">{{ "preference.eDelivery.errorEmailRequired" | translate }}</ng-message>
          <ng-message when="emailFormat">{{ "preference.eDelivery.errorEmailInvalid" | translate }}</ng-message>
      </ng-messages>
    </div>

    <div class ="document-review" >
      <p ng-bind-html="'preference.eDelivery.legal.section' | translate"> </p>
    </div>

    <div class="pull-right margin-bottom-100 margin-top-100">
        <button 
          type="submit"
          data-ng-show="isModalWindow"
          ng-disabled="primaryPlanT"
          class="btn btn-primary"
          id="submit"
          translate="preference.eDelivery.button.save" />
        <button 
          type="submit"
          class="btn btn-primary btn-block margin-top-default"
          data-ng-hide="isModalWindow"
          id="submit-processing"
          data-ng-disabled="showSpinner"
        >
          <span data-ng-hide="showSpinner" translate="preference.eDelivery.button.save" />
          <span data-ng-show="showSpinner">
            <i class="fa fa-circle-o-notch fa-spin"></i>
              Processing ...
          </span>
        </button>
    </div>
  </form>
</div>
</div>
`;
export default communicationPreferencesTemplate;
