import React, { useState } from "react";
import { useTranslations } from "../../queries";
import { retrievePlansToLink, updNPDIPPTEnroll } from "../../services/npdi/npdiService";
import { useQuery } from "@tanstack/react-query";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import NpdiLinkAccountsEvents from "../../../npdi/events/NpdiLinkAccountsEvents";
import sendAmplitudeEvent from "../../../utils/sentryMetricsLogging/sendAmplitudeEvent";

interface NpdiLinkTranslations {
    npdiForm: {
        "0000": string;
        accountExists: {
            accountsLinked: string;
            continue: string;
            link: string;
            linkText: string;
            pleaseClick: string;
            title: string;
        };
        linkAccounts: {
            buttonContinue: string;
            instructions: string;
            labelAccounts: string;
            labelUsername: string;
            linkLogIn: string;
            msgSuccess: string;
            title: string;
        };
    };
}

const NpdiLinkAccounts = () => {
    const { npdiForm }: NpdiLinkTranslations = useTranslations();
    const { data } = useQuery({
        queryKey: ["retrieve-plans"],
        queryFn: () => retrievePlansToLink()
    });
    const [errCode, setErrCode] = useState("");
    const [status, setStatus] = useState(false);
    const showLinkAccountsSection = !status;

    const submit = () => {
        sendAmplitudeEvent({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: NpdiLinkAccountsEvents.CONTINUE
            }
        });

        updNPDIPPTEnroll().then(
            () => {
                setStatus(true);
            },
            (err) => {
                setErrCode(err.code);
            }
        );
    };

    return (
        <div className="col-sm-8 col-sm-offset-2 col-xs-12">
            <header className="contained-form-header">
                <h1>{npdiForm?.linkAccounts?.title}</h1>
            </header>
            <div id="content-container">
                <div className="inner-container with-padding with-shadow">
                    {errCode && (
                        <div className="form-group has-error" id="screenReaderAuth" tabIndex={0}>
                            <span className="help-block">{npdiForm && npdiForm[errCode]}</span>
                        </div>
                    )}
                    {showLinkAccountsSection ? (
                        <form
                            name="npdiForm"
                            autoComplete="off"
                            noValidate
                            role="form"
                            onSubmit={submit}
                        >
                            <p>{npdiForm?.linkAccounts?.instructions}</p>
                            {data?.username.length > 0 && (
                                <p>
                                    <strong>{npdiForm?.linkAccounts?.labelUsername}: </strong>
                                    {data?.username}
                                </p>
                            )}
                            {data?.accounts.length > 0 && (
                                <div className="margin-top-200">
                                    <h2>{npdiForm?.linkAccounts?.labelAccounts}</h2>

                                    <ul className="account-listing list-unstyled">
                                        {data?.plans.map((plan) => {
                                            return (
                                                <li key={plan.groupName}>
                                                    <span className="plan">{plan.groupName}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg btn-block margin-top-default"
                            >
                                {npdiForm?.linkAccounts?.buttonContinue}
                            </button>
                        </form>
                    ) : (
                        <div>
                            <p className="margin-bottom-0">
                                <i className="em-checkbox-icon"></i>{" "}
                                {window.accu !== "MinnesotaWR" && (
                                    <span>{npdiForm?.linkAccounts?.msgSuccess}</span>
                                )}
                            </p>
                            {window.accu !== "MinnesotaWR" && (
                                <a href="#/login">{npdiForm?.linkAccounts?.linkLogIn}</a>
                            )}
                            {window.accu === "MinnesotaWR" && (
                                <span>
                                    {npdiForm?.accountExists.accountsLinked}
                                    {npdiForm?.accountExists.pleaseClick}
                                    <a href={npdiForm?.accountExists.link}>
                                        {npdiForm?.accountExists.linkText}
                                    </a>
                                    {npdiForm?.accountExists.continue}
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NpdiLinkAccounts;
