import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { checkValidation } from "../../hooks/formValidations";
import TranslationService from "../../services/translationService";
import { detailsForm } from "../../utils/otsConstants";

const submitButtonStyles = {
    disabled:
        "continue btn btn-primary btn-default__accessible btn-lg btn-block margin-top-default outline-btn is-disabled",
    enabled: "continue btn btn-primary btn-lg btn-block margin-top-default outline-btn"
};

const REFERRAL = "referral";
const OTS = "ots";
// TODO This code will be REMOVED as we convert this code to React
const AccountOpenDetailsFormAngular = ({
    handleDetailsFormSubmit,
    formData,
    stateCodes,
    hasReferrerParam
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        trigger,
        setValue
    } = useForm();
    const [translations, setTranslations] = useState(null);

    useEffect(() => {
        TranslationService.getTranslations((resp) => {
            setTranslations(resp);
        });
    }, []);

    const handleFormSubmit = (data) => {
        console.log(data);
        handleDetailsFormSubmit(data);
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setValue(name, value);
    };

    const getStateNameByCode = (code) => {
        const state = stateCodes.find((state) => state.code === code);
        return state?.name || null;
    };

    const key = hasReferrerParam ? REFERRAL : OTS;

    return translations ? (
        <div>
            <div className="form-container">
                <div role="form" aria-labelledby="title" aria-describedby="description">
                    <h1 id="title" className="ots-prelogin-header" role="heading">
                        {translations.accountOpen[key].title}
                    </h1>
                    <div className="inner-container with-padding with-shadow ira-pre-account-form">
                        <p id="description">{translations.accountOpen[key].description1}</p>

                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <div className="row">
                                {detailsForm.map(({ info, size, label, defaultValue }, idx) => (
                                    <div
                                        key={info}
                                        className={[
                                            "form-group",
                                            errors[info] && "has-error",
                                            size
                                        ].join(" ")}
                                    >
                                        <label htmlFor={info} className="control-label">
                                            {translations.accountOpen.detailsForm[info]}
                                        </label>
                                        {info === "state" ? (
                                            <select
                                                autoFocus={idx === 0}
                                                className={"form-control"}
                                                name={info}
                                                id={info}
                                                {...register(info, checkValidation(info, label))}
                                                defaultValue={formData[info] || ""}
                                                onBlur={() => trigger(info)}
                                                onChange={handleFieldChange}
                                            >
                                                <option key="blank" value={formData[info] || ""}>
                                                    {getStateNameByCode(formData[info]) ||
                                                        defaultValue}
                                                </option>
                                                {stateCodes &&
                                                    stateCodes.map((op) => (
                                                        <option key={op.code} value={op.code}>
                                                            {op.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        ) : (
                                            <input
                                                autoFocus={idx === 0}
                                                type="text"
                                                className={`form-control ${
                                                    errors[info] ? "error" : ""
                                                }`}
                                                id={info}
                                                name={info}
                                                {...register(info, checkValidation(info, label))}
                                                defaultValue={formData[info] || ""}
                                                onBlur={() => trigger(info)}
                                                onChange={handleFieldChange}
                                            />
                                        )}
                                        {errors[info] && (
                                            <div className="messages">
                                                <p aria-live="assertive" className="msg">
                                                    {errors[info].message}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <button
                                        type="submit"
                                        aria-disabled={!isValid}
                                        className={
                                            isValid
                                                ? submitButtonStyles.enabled
                                                : submitButtonStyles.disabled
                                        }
                                    >
                                        {translations.button.continue}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

AccountOpenDetailsFormAngular.propTypes = {
    handleDetailsFormSubmit: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    stateCodes: PropTypes.array.isRequired,
    hasReferrerParam: PropTypes.string.isRequired
};

export default AccountOpenDetailsFormAngular;
