import React from "react";
import { useTranslations } from "../../queries";
import { Routes, Route } from "react-router-dom";
import { GROUP, PIN } from "./routes";
import RegistrationNavLink from "../shared/registrationNavLink/RegistrationNavLink";
import RegisterWithoutPin from "./registerWithoutPin/RegisterWithoutPin";
import RegisterWithPin from "./registerWithPin/RegisterWithPin";
import RegisterWithGroupPassword from "./registerWithGroupPassword/RegisterWithGroupPassword";
import { getAccuCode } from "../../services/accuCodeService";
import useAccuCustomization from "../../queries/useAccuCustomization";
import RegisterWithUniqueIdentifier from "./registerWithUniqueIdentifier/RegisterWithUniqueIdentifier";

interface RegisterTranslations {
    enterAccountInfo: string;
    lookupAccount: string;
    tabs: {
        groupPassword: string;
        havePin: string;
        noPin: string;
    };
}

const FLOW_NAME = "registration";

const Register = () => {
    const { lookupAccount, enterAccountInfo, tabs } = useTranslations<RegisterTranslations>();
    const accu = getAccuCode();
    const { data: accuCustomization, isLoading } = useAccuCustomization(accu);
    const showRegisterWithUniqueIdentifier =
        accuCustomization && accuCustomization.showLoginSetupRecoveryRadio;

    if (isLoading) {
        return (
            <div className="loaderBackground">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className="registration-container">
            <div className="registration form">
                <header className="contained-form-header margin-top-default">
                    <h1>{lookupAccount}</h1>
                    <p>{enterAccountInfo}</p>
                </header>
                {!showRegisterWithUniqueIdentifier && (
                    <ul className="nav nav-tabs" role="tablist">
                        <RegistrationNavLink path={""} tabLabel={tabs.noPin} />
                        <RegistrationNavLink path={PIN} tabLabel={tabs.havePin} />
                        <RegistrationNavLink path={GROUP} tabLabel={tabs.groupPassword} />
                    </ul>
                )}

                <div className="tab-content hidden-xs">
                    <div role="tabpanel" className="tab-pane active" id="registrationContent">
                        <Routes>
                            <Route
                                index
                                element={
                                    showRegisterWithUniqueIdentifier ? (
                                        <RegisterWithUniqueIdentifier />
                                    ) : (
                                        <RegisterWithoutPin flowName={FLOW_NAME} />
                                    )
                                }
                            />
                            <Route path={PIN} element={<RegisterWithPin />} />
                            <Route path={GROUP} element={<RegisterWithGroupPassword />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
